$tsa-local-padding-top: 5rem;

.root {
  padding-top: $tsa-local-padding-top;
  padding-bottom: 5rem,
}

.inner {
  position: relative;
}

.image {
  position: absolute;
  top: -$tsa-local-padding-top;
  right: 0;
  left: 0;
  z-index: 0;
  opacity: 0.75;
}

.svgPath {
  fill: var(--mantine-color-gray-0);
}

.content {
  position: relative;
  z-index: 1;
}

.title {
  text-align: center;
  font-weight: 900;
  font-size: 38px;
}

.description {
  max-width: 540px;
  margin: auto;
  margin-top: var(--mantine-spacing-xl);
  margin-bottom: var(--mantine-spacing-xl);
}

.link {
  color: var(--mantine-color-white);
}